<template>
  <div class="v-input-thousands v-input-thousands--default" :class="classList">
    <div class="v-input-thousands__wrapper">
      <div class="v-input-thousands__inner-no-padding">
        <input
          ref="input"
          class="v-input-thousands__native"
          v-bind="$attrs"
          :value="textValue ? textValue : splittedValue"
          type="text"
          :disabled="disabled"
          @keydown.enter="$refs.input.blur()"
          @input="onInput"
          @change="onChange"
          @focus="onFocus"
          @blur="onBlur"
        />
        <div class="v-input-thousands__currency">
          <slot />
        </div>
      </div>
      <transition name="expand">
        <div v-show="error" class="v-input-thousands-hint">
          <small
            class="inline-block"
            :class="[error ? 'v-input-thousands-hint__error' : '']"
          >
            {{ error }}
          </small>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  name: "VInputThousands",

  props: {
    value: {
      type: [String, Number],
      default: "",
    },
    /**
     * Определяет классы, которые будут модифицировать размер
     */

    defaultValue: {
      type: [Number, String],
      default: 0,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    delimiter: {
      type: String,
      default: " ",
    },

    decimalMark: {
      type: String,
      default: ".",
    },

    positiveOnly: {
      type: Boolean,
      default: true,
    },

    integerOnly: {
      type: Boolean,
      default: false,
    },

    decimalCount: {
      type: Number,
      default: 2,
    },

    textValue: {
      type: String,
      default: "",
    },

    isError: Boolean,

    error: {
      type: String,
      default: "",
    },
  },
  emits: ["change", "focus", "blur", "input"],

  data() {
    return {
      isFocused: false,
      splittedValue: this.splitThousands(this.value),
    };
  },

  computed: {
    classList() {
      return [
        {
          "is-active": this.value,
          "is-focus": this.isFocused,
          "is-disabled": this.disabled,
          "has-error": this.isError,
          "is-error": this.error,
        },
      ];
    },

    cleanValue() {
      return Number(this.splittedValue.split(this.delimiter).join(""));
    },
  },

  watch: {
    value(newValue) {
      if (newValue !== this.cleanValue) {
        this.splittedValue = this.splitThousands(newValue);
      }
    },
  },

  methods: {
    onChange() {
      // strip any leading zeros
      if (this.splittedValue.length > 1 && this.splittedValue[0] === "0") {
        this.splittedValue = this.splitThousands(
          this.cleanValue.toString().replace(/^(-)?0+(?=\d)/, "$1"),
        );
      }

      if (
        (!this.splittedValue || this.splittedValue === "0") &&
        !this.textValue
      ) {
        this.splittedValue = this.splitThousands(this.defaultValue);
      }

      this.$nextTick(() => {
        this.$emit("change", this.cleanValue);
      });
    },

    onFocus(e) {
      this.isFocused = true;
      this.$emit("focus", e);
    },

    onBlur(e) {
      this.isFocused = false;
      this.onChange();
      this.$emit("blur", e);
    },

    onInput(e) {
      let endPos = e.target.selectionEnd;
      const oldValue = e.target.value;
      const newValue = this.splitThousands(e.target.value);
      e.target.value = newValue;
      this.splittedValue = newValue;

      this.$nextTick(() => {
        endPos = this.getNextCursorPosition(
          endPos,
          oldValue,
          newValue,
          this.delimiter,
        );
        this.setCursor(e.target, endPos);
        this.$emit("input", this.cleanValue);
      });
    },

    splitThousands(value) {
      if (typeof value !== "number" && typeof value !== "string") {
        console.warn("[CInputThousands] Wrong prop value");
        return "";
      }

      let partDecimal = "";
      let parts;

      // strip alphabet letters
      value = value.toString();
      value = value
        .replace(/[A-Za-z]/g, "")
        // replace the first decimal mark with reserved placeholder
        .replace(this.decimalMark, "M")

        // strip non-numeric letters except minus and "M"
        // this is to ensure prefix has been stripped
        .replace(/[^\dM-]/g, "")

        // replace the leading minus with reserved placeholder
        .replace(/^-/, "N")

        // strip the other minus sign (if present)
        .replace(/-/g, "")

        // replace the minus sign (if present)
        .replace("N", this.positiveOnly ? "" : "-")

        // replace decimal mark
        .replace("M", this.decimalMark);

      if (this.integerOnly) {
        // запрет на ввод любых символов, кроме цифр
        value = value.replace(/[^0-9]/g, "");
      }

      // strip any leading zeros
      // if (owner.stripLeadingZeroes) {
      // value = value.replace(/^(-)?0+(?=\d)/, '$1');
      // }

      const partSign = value.slice(0, 1) === "-" ? "-" : "";
      const partSignAndPrefix = partSign;
      let partInteger = value;

      if (value.includes(this.decimalMark)) {
        parts = value.split(this.decimalMark);
        partInteger = parts[0];
        partDecimal = this.decimalMark + parts[1].slice(0, this.decimalCount);
      }
      if (partSign === "-") {
        partInteger = partInteger.slice(1);
      }
      partInteger = partInteger.replace(
        /(\d)(?=(\d{3})+$)/g,
        `$1${this.delimiter}`,
      );

      return (
        partSignAndPrefix +
        partInteger.toString() +
        (this.decimalCount > 0 ? partDecimal.toString() : "")
      );
    },

    getNextCursorPosition(prevPos, oldValue, newValue, delimiter) {
      return oldValue.length === prevPos
        ? newValue.length
        : prevPos +
            this.getPositionOffset(prevPos, oldValue, newValue, delimiter);
    },

    getPositionOffset(prevPos, oldValue, newValue, delimiter) {
      const oldRawValue = this.stripDelimiters(
        oldValue.slice(0, prevPos),
        delimiter,
      );
      const newRawValue = this.stripDelimiters(
        newValue.slice(0, prevPos),
        delimiter,
      );
      const lengthOffset = oldRawValue.length - newRawValue.length;
      return lengthOffset !== 0 ? lengthOffset / Math.abs(lengthOffset) : 0;
    },

    stripDelimiters(value, delimiter) {
      const delimiterRE = delimiter
        ? new RegExp(delimiter.replace(/([.?*+^$[\]\\(){}|-])/g, "\\$1"), "g")
        : "";
      return value.replace(delimiterRE, "");
    },

    setCursor(el, position) {
      const setSelectionRange = function () {
        el.setSelectionRange(position, position);
      };

      if (el === document.activeElement) {
        setSelectionRange();
        // Android Fix
        setTimeout(setSelectionRange, 1);
      }
    },
  },
};
</script>

<style lang="scss">
.v-input-thousands {
  position: relative;

  .v-input-thousands__wrapper {
    background-color: transparent;
    border: 2px solid $color-border-input;

    &:focus-within {
      border: 2px solid $brand-green;
    }
  }

  &__native {
    position: relative;
    transition:
      background-color 0.4s ease,
      color 0.4s ease;
    z-index: 2;
    width: 100%;
    height: 4.6rem;
    padding: 0 1.7rem 0 4.8rem;
    border: 2px solid transparent;
    font-weight: 400;
    font-size: 1.5rem;
    line-height: normal;
    background-color: $input-color;
    color: $body-color;

    &:-webkit-autofill,
    &:-webkit-autofill:focus {
      transition:
        background-color 600000s 0s,
        color 600000s 0s;
    }
  }

  &__label {
    z-index: 1;
    position: absolute;
    top: 50%;
    left: 3rem;
    font-size: 1.6rem;
    color: $base-0;
    pointer-events: none;
    transform: translateY(-50%);
    transition: all 0.3s ease;
  }

  &--default {
    .v-input-thousands__label {
      color: $body-color;
    }
  }

  &.is-active {
    .v-input-thousands__label {
      font-size: 1.2rem;
      line-height: 1.4rem;
      color: rgba($base-0, 0.5);
      transform: translateY(-130%);
    }
  }

  &.has-label {
    .v-input-thousands__native {
      font-size: 1.6rem;
      line-height: 1.9rem;
      margin-top: 0.5rem;
    }
  }

  &.is-focused {
    .v-input-thousands__label {
      font-size: 1rem;
      line-height: 1.2rem;
      color: rgba($body-color, 0.5);
      transform: translateY(-150%);
    }
  }

  &.has-error {
    .v-input-thousands__wrapper {
      border: 2px solid $brand-red;
    }
  }

  &-hint {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-weight: 400;
    font-size: 1.4rem;
    line-height: normal;
    margin-top: 0.5rem;
    color: $base-500;

    &__error {
      color: $brand-red;
    }
  }

  &.is-disabled {
    pointer-events: none;
  }

  &.is-copy {
    .v-input__wrapper {
      border-radius: 8px;
    }
  }

  &__inner {
    position: relative;
    display: inline-flex;
    width: 100%;
    height: 100%;
    padding-top: 1.8rem;
  }

  &__inner-no-padding {
    position: relative;
    width: 100%;
    height: 100%;
  }

  &__loader {
    position: absolute;
    z-index: 3;
    top: 50%;
    height: 25px;
    width: 89px;
    background-color: hsl(0deg 0% 39% / 30%);
    border-radius: 5rem;
    transform: translateY(-50%);
  }

  &__error {
    position: absolute;
    bottom: -2rem;
    left: 3rem;
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1.4rem;
    letter-spacing: -0.02em;
    color: red;
  }

  &__currency {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 1.7rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.6rem;
    height: 2.6rem;
    background: $base-800;
    border-radius: 50%;
    z-index: 2;
  }
}
</style>
