<template>
  <div v-if="options?.length > 1">
    <p v-if="title" class="p-small" :class="$style.title">
      {{
        options.some((obj) => obj.hasOwnProperty("network")) ? "Сеть" : "Банк"
      }}:
    </p>
    <div :class="[$style.wrap, { [$style.space]: !title }]">
      <UiRadioVRadio
        v-for="option in options"
        :key="option"
        :option-value="option.id"
        :option-checked="option.active"
        :option-label="option.network || option.currency"
        @click="activeOption = option"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  options: {
    type: Array,
    default: () => [],
  },
  title: {
    type: Boolean,
    default: true,
  },
});

const emit = defineEmits(["optionChange"]);

const activeOption = computed({
  get() {
    if (!props.options) return;
    return props.options.find((option) => option.active);
  },
  set(value) {
    emit("optionChange", value.id);
    props.options.forEach((option: any) => {
      option.active = option === value.id;
    });
  },
});
</script>

<style lang="scss" module>
.title {
  margin-bottom: 1rem;
}

.wrap {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.space {
  justify-content: space-between;
}
</style>
